import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import stakeABI from "./stakeABI.json"; // ABI for your contract
import tokenABI from "./tokenABI.json"; // ABI for the token contract

const stakeAddress = "0xe0Ac697013a8311Ba2d3997F82774e6a4fCCbD3f"; // Staking contract address
const tokenAddress = "0xD66084fD621bF19a9A8b69a61aa6D2681d5C2E95"; // Token contract address

const StakeToken = () => {
    const [tokenBalance, setTokenBalance] = useState("0");
    const [popupMessage, setPopupMessage] = useState("");
    const { isConnected, address } = useAccount();

    // Read user's token balance
    const { data: balanceData, error: balanceError } = useReadContract({
        address: tokenAddress,
        abi: tokenABI,
        functionName: "balanceOf",
        args: [address],
        enabled: isConnected,
    });

    useEffect(() => {
        if (balanceData) {
            const formattedBalance = parseFloat(balanceData) / 1e18; // Adjust based on token decimals
            setTokenBalance(formattedBalance.toString());
        }
        if (balanceError) {
            displayPopup("Error fetching token balance: " + balanceError.message);
        }
    }, [balanceData, balanceError]);

    // Write to the staking contract
    const { write: stakeTokens } = useWriteContract({
        address: stakeAddress,
        abi: stakeABI,
        functionName: "stake",
        args: [tokenBalance > 0 ? (parseFloat(tokenBalance) * 1e18).toString() : "0"], // Convert to wei
        enabled: isConnected && parseFloat(tokenBalance) > 0,
    });

    // Display popup message for feedback
    const displayPopup = (message) => {
        setPopupMessage(message);
        setTimeout(() => {
            setPopupMessage("");
        }, 5000); // Clear message after 5 seconds
    };

    // Stake function
    const handleStake = async () => {
        if (!isConnected) {
            displayPopup("Please connect your wallet.");
            return;
        }

        if (parseFloat(tokenBalance) <= 0) {
            displayPopup("You do not have any tokens to stake.");
            return;
        }

        try {
            await stakeTokens();
            displayPopup("Staking successful! Your tokens are staked for 1 year.");
        } catch (error) {
            console.error("Error staking tokens:", error.message);
            displayPopup("Error staking tokens: " + error.message);
        }
    };

    return (
        <section id="stake" className="about-area pt-50 pb-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 top" style={{ display: "flex" }}>
                        <Link to="/" className="back-arrow">
                            ← Back
                        </Link>
                        <ConnectButton 
                            showBalance={false}
                            accountStatus="address"
                            label={isConnected ? "Connected" : "Connect"}
                        />
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="about-content stake-content wow fadeInRight" data-wow-delay=".2s">
                            <div className="section-title stake-text mb-30">
                                <h2 className="title">Stake Your Tokens, Earn 50% APY!</h2>
                            </div>
                            <p>
                                Maximize your rewards by staking your tokens with an incredible 50% Annual
                                Percentage Yield (APY). Lock in your assets for a one-year period and watch your
                                investment grow. Staking not only helps secure the network but also gives you the
                                opportunity to earn high returns while holding your tokens.
                                <ul>
                                    <li>50% APY: Guaranteed returns over a one-year period.</li>
                                    <li>One-Year Lock: Your tokens will be securely locked for 12 months.</li>
                                    <li>Claim Rewards: Unlock your rewards at the end of the staking period.</li>
                                </ul>
                            </p>
                            <p>Token Balance: {tokenBalance} Tokens</p>
                            <div className="row stake-button">
                                <div className="col-lg-12">
                                    <button 
                                        className="btn" 
                                        onClick={handleStake} 
                                        disabled={!isConnected || parseFloat(tokenBalance) <= 0}
                                    >
                                        Stake
                                    </button>
                                    <button className="btn" disabled style={{ marginLeft: "10px" }}>
                                        Claim
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {popupMessage && (
                <div className="popup">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                    </div>
                </div>
            )}
        </section>
    );
};

export default StakeToken;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
// import {
//     useAccount,
//     useBalance,
//     usePrepareTransactionRequest,
//     useSendTransaction,
//     useContractRead,
//     useContractWrite
// } from "wagmi";

// import stakeABI from "./stakeABI.json";
// import tokenABI from "./tokenABI.json";

// const stakeAddress = "0xe0Ac697013a8311Ba2d3997F82774e6a4fCCbD3f"; // Staking contract address
// const tokenAddress = "0xD66084fD621bF19a9A8b69a61aa6D2681d5C2E95"; // Token contract address

// const StakeToken = () => {
//     const [popupMessage, setPopupMessage] = useState("");
//     const { address, isConnected } = useAccount();

//     // Fetch token balance using Wagmi's useBalance hook
//     const { data: tokenBalanceData, refetch: refetchTokenBalance } = useBalance({
//         address,
//         token: tokenAddress,
//         watch: true,
//     });

//     // Prepare the transaction to stake tokens
//     const { config: stakeConfig } = usePrepareTransactionRequest({
//         request: {
//             to: stakeAddress,
//             data: "0x", // Placeholder data; update with encoded function call
//             // Optional value field if sending ETH along with the transaction
//         },
//         enabled: isConnected && tokenBalanceData?.value > 0,
//     });

//     // Send the staking transaction
//     const { sendTransaction: sendStakeTransaction } = useSendTransaction({
//         ...stakeConfig,
//         onSuccess: () => {
//             displayPopup("Staking successful! Your tokens are staked for 1 year.");
//             refetchTokenBalance();
//         },
//         onError: (error) => {
//             displayPopup("Error staking tokens: " + error.message);
//         }
//     });

//     // Display popup message for feedback
//     const displayPopup = (message) => {
//         setPopupMessage(message);
//         setTimeout(() => {
//             setPopupMessage("");
//         }, 5000); // Clear message after 5 seconds
//     };

//     // Stake function
//         const stakeTokens = () => {
//             if (!isConnected) {
//                 displayPopup("Please connect your wallet.");
//                 return;
//             }
        
//             if (!tokenBalanceData?.value || tokenBalanceData.value <= 0) {
//                 displayPopup("You don't have enough tokens to stake.");
//                 return;
//             }
        
//             sendStakeTransaction?.();
//         };

//     return (
//         <section id="stake" className="about-area pt-50 pb-30">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-12 top" style={{ display: 'flex' }}>
//                         <Link to="/" className="back-arrow">
//                             ← Back
//                         </Link>
//                         <ConnectButton
//                             showBalance={false}
//                             accountStatus="address"
//                             label={isConnected ? "Connected" : "Connect"}
//                         />
//                     </div>
//                 </div>
//                 <div className="row align-items-center">
//                     <div className="col-lg-12">
//                         <div className="about-content stake-content wow fadeInRight" data-wow-delay=".2s">
//                             <div className="section-title stake-text mb-30">
//                                 <h2 className="title">Stake Your Tokens, Earn 50% APY!</h2>
//                             </div>
//                             <p>
//                                 Maximize your rewards by staking your tokens with an incredible 50% Annual
//                                 Percentage Yield (APY). Lock in your assets for a one-year period and watch your
//                                 investment grow. Staking not only helps secure the network but also gives you the
//                                 opportunity to earn high returns while holding your tokens.
//                                 <ul>
//                                     <li>50% APY: Guaranteed returns over a one-year period.</li>
//                                     <li>One-Year Lock: Your tokens will be securely locked for 12 months.</li>
//                                     <li>Claim Rewards: Unlock your rewards at the end of the staking period.</li>
//                                 </ul>
//                             </p>
//                             <p>Token Balance: {tokenBalanceData?.formatted || "0"} Tokens</p>
//                             <div className="row stake-button">
//                                 <div className="col-lg-12">
//                                     <button
//                                         className="btn"
//                                         onClick={stakeTokens}
//                                         disabled={!isConnected }
//                                     >
//                                         Stake
//                                     </button>
//                                     <button className="btn" disabled style={{ marginLeft: "10px" }}>
//                                         Claim
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {popupMessage && (
//                 <div className="popup">
//                     <div className="popup-content">
//                         <p>{popupMessage}</p>
//                     </div>
//                 </div>
//             )}
//         </section>
//     );
// };

// export default StakeToken;
