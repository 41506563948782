import React from "react";
import img01 from "../../assets/img/images/newmascot.png";
import { Link } from "react-router-dom";
import { handleClickScroll } from "../../lib/helpers";


const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row about-row align-items-center">
        <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
              <span className="sub-title">Who We Are</span>
                <h2 className="title">
                METALLIUM ALPHA
                </h2>
                </div>
              <p>

              A new age of "Gold Rush" is upon us. Crypto space probes have discovered a valuable new metal, 
              "Metallium Alpha," on Planet Terra101. This presents a unique opportunity for miners to venture forth 
              and stake their claim. It's time to pack your bags, embark on this thrilling journey, and potentially achieve unparalleled wealth.
              </p>
              <Link to="#box" onClick={() => handleClickScroll("box")} className="btn">
                Purchase Tokens
              </Link>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              {/* <img src={img02} alt="" className="img-two" /> */}
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
