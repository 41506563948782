import React from "react";
import { Link } from "react-router-dom";

const RoadmapOneItem = (props) => {
  return (
    <div className="choose-item roadmap-content-new">
      {/* <div className="choose-icon">
        <img src={props.item.src} alt={props.item.alt} />
      </div> */}
      <div className="items">
        <h2 className="title">
          {props.item.title}
        </h2>
        <h3>{props.item.titletwo}</h3>
        <ul>
            <li>
            {props.item.dot1}
            </li>
            <li>
            {props.item.dot2}
            </li>
            <li>
            {props.item.dot3}
            </li>
            <li>
            {props.item.dot4}
            </li>
        </ul>
      </div>
    </div>
  );
};

export default RoadmapOneItem;
