import React from "react";
import img01 from "../../assets/img/images/tokenomicsMTLA.png";
import { Link } from "react-router-dom";

const Tokenomics = (props) => {
  return (
    <section id="tokenomics" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
        <div className="col-lg-12">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title tokenomics-heading text-center mb-30">
                <h2 className="title">
                Tokenomics </h2>
                <br/>
                <h4 style={{color: '#c50'}}>Token Contract : 0xD66084fD621bF19a9A8b69a61aa6D2681d5C2E95</h4>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-img text-center wow fadeInLeft" data-wow-delay=".2s">
            <div className="row tokenomics">
                <div className="col-lg-6">
                <p>
                    Name
                </p>
                <p>
                    Symbol
                </p>      
                <p>
                    Supply
                </p> 
              

                </div> 
                <div className="col-lg-6">
                    <p>
                    Metallium Alpha 
                    </p>
                    <p>
                        MTLA
                    </p>
                    <p>
                    50 000 000 000                     
                    </p>
                   
                </div>      
               
                </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-img text-center wow fadeInLeft" data-wow-delay=".3s">
              <img src={img01} alt="" />
              {/* <img src={img02} alt="" className="img-two" /> */}
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
