import React, { useEffect, useState } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import icon01 from "../../assets/img/icon/choose_icon01.svg";
import icon02 from "../../assets/img/icon/choose_icon02.svg";
import icon03 from "../../assets/img/icon/choose_icon03.svg";
import icon04 from "../../assets/img/icon/choose_icon04.svg";
import WhyChooseUsItem from "./WhyChooseUsItem";

const WhyChooseUs = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      src: icon01,
      alt: "",
      link: "/",
      title: "Lucrative Gaming platform",
      description: "By the time the gaming platform is launched, our token will already be listed, ensuring that users are aware of its value from the outset. With this foundation in place, users can feel confident that their experience will be rewarding and meet their expectations.",
    },
    {
      src: icon02,
      alt: "",
      link: "/",
      title: "Decentralized Payments",
      description: "Metallium Alpha leverage blockchain technology to enable peer-to-peer transactions without intermediaries like banks. This approach enhances security, transparency, and autonomy, allowing for faster and more cost-effective cross-border exchanges. In the future, we will also offer a POS machine payment service, further facilitating seamless transactions for businesses and consumers alike.",
    },
    {
      src: icon03,
      alt: "",
      link: "/",
      title: <>Layer 1 Blockchain</>,
      description: "Metallium Alpha will develop its own blockchain in the future, positioning our token, MTLA, as a powerful asset within this ecosystem. This strategic initiative will enhance the functionality and value of MTLA, empowering users with greater opportunities for engagement and investment.",
    },
  ];

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => (old == 6 ? 0 : old + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toMultiply = 100 / slider_items.length;

  return (
    <section id="features" className="choose-area pt-130 pb-130">
      <div className="container">
      <div className="row">
        <div className="col-lg-12 section-title">
        <span className='sub-title'>Why Choose Us</span>
          <h2>Core features</h2>
          <p>
The Metallium Alpha Token (MTLA) is a revolutionary digital asset built on the Ethereum blockchain, designed to provide robust and secure transactions within the decentralized finance (DeFi) ecosystem. As a utility token, MTLA is engineered to offer seamless integration across various platforms, enabling users to engage in staking, trading, and decentralized applications (dApps) with enhanced liquidity and minimal friction.<br/><br/>

With a focus on innovation, Metallium Alpha leverages Ethereum's smart contract capabilities to deliver a transparent and trusted environment for all investors. MTLA offers a gateway to the future of digital finance, where efficiency, security, and scalability are at the forefront.<br/><br/>

MTLA is positioned to play a pivotal role in upcoming developments. This is due to our aim in changing the future financial transactions and ultimately create a blockchain in the future.<br/><br/>

A gaming platform will be launched, designed to merge the excitement of gaming with the lucrative potential of resource mining. This innovative platform allows players to engage in gameplay while earning real-world value through in-game assets.
</p>
        </div>
      </div>

        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <h2 className="title">
              
                WHY IT IS IMPORTANT TO INVEST IN <span> METALLIUM ALPHA</span>?
              </h2>
            </div>
          </div>
        </div>

        <div className="row choose-active">
          <SlickSlider settings={slickSettings}>
            {slider_items.map((item, index) => (
              <div key={index} className="col-lg-3">
                <WhyChooseUsItem item={item} />
              </div>
            ))}
          </SlickSlider>
        </div>

        {/* <div className="slide-progressbar">
          <div
            style={{
              // width: `${count * toMultiply}%`,
              backgroundSize: `${count * toMultiply}% 100%`,
            }}
            className="slide-filler"
          />
        </div> */}

      </div>
    </section>
  );
};

export default WhyChooseUs;
