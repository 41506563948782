import React from "react";
import Banner from "../../components/Banner/Banner";
import ContactTwo from "../../components/Contact/ContactTwo"
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import About from "../../components/About/About";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import RoadmapOne from "../../components/Roadmap/RoadmapOne";
import Tokenomics from "../../components/Tokenomics/Tokenomics";
import Faq from "../../components/Faq/Faq";
import TeamOne from "../../components/Team/TeamOne";

const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">

   
        <div className="area-bg">
        <Banner />
        <WhoWeAre />
        <About />
        <WhyChooseUs />
        <Tokenomics />
        <RoadmapOne />
        <WhitePaper />
        <TeamOne />
        <Faq />
        <Disclaimer/>

        </div>

      <ContactTwo />
      </main>
    </LayoutOne>
  );
};

export default Home;
