// import React from "react";
// import Countdown from "react-countdown";

// const CountDownOne = () => {
//   // Renderer callback with condition
//   const renderer = ({ days, hours, minutes, seconds, completed }) => {
//     if (completed) {
//       return (
//         <>
//           <div className="time-count day">
//             <span>{"00"}</span>Days
//           </div>
//           <div className="time-count hour">
//             <span>{"00"}</span>hour
//           </div>
//           <div className="time-count min">
//             <span>{"00"}</span>minute
//           </div>
//           <div className="time-count sec">
//             <span>{"00"}</span>second
//           </div>
//         </>
//       );
//     } else {
//       return (
//         <>
//           <div className="time-count day">
//             <span>{days < 10 ? "0" + days : days}</span>Days
//           </div>
//           <div className="time-count hour">
//             <span>{hours < 10 ? "0" + hours : hours}</span>hour
//           </div>
//           <div className="time-count min">
//             <span>{minutes < 10 ? "0" + minutes : minutes}</span>minute
//           </div>
//           <div className="time-count sec">
//             <span>{seconds < 10 ? "0" + seconds : seconds}</span>second
//           </div>
//         </>
//       );
//     }
//   };

//   // Set countdown for 5 days (in milliseconds)
//   const fiveDaysInMilliseconds = 5 * 24 * 60 * 60 * 1000;

//   return (
//     <div className="coming-time box-timer">
//       <Countdown date={Date.now() + fiveDaysInMilliseconds} renderer={renderer} />
//     </div>
//   );
// };

// export default CountDownOne;

import React from "react";
import Countdown from "react-countdown";

const CountDownOne = () => {
  // Calculate the target date (12:00 AM on October 27th)
  const targetDate = new Date("2024-10-27T00:00:00").getTime();

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span> Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span> hour
          </div>
          <div className="time-count min">
            <span>{"00"}</span> minute
          </div>
          <div className="time-count sec">
            <span>{"00"}</span> second
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Days
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>Hours
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>Minutes
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>Seconds
          </div>
        </>
      );
    }
  };

  return (
    <div className="coming-time box-timer">
      {/* <Countdown date={targetDate} renderer={renderer} /> */}
      <h3>Presale is Active Now</h3>
    </div>
  );
};

export default CountDownOne;
