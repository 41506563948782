import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo1.png";
import { scrollToTop } from "../../lib/helpers";
import { handleClickScroll } from "../../lib/helpers";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane, faXTwitter} from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from "@fortawesome/free-brands-svg-icons/faTelegram";


const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="BigTech Logo" />
                  </Link>
                  <div className="footer-content">
                    <p>
                    A new age of "Gold Rush" is upon us. Crypto space probes have discovered a valuable new metal, "Metallium Alpha," on Planet Terra101. This presents a unique opportunity for miners to venture forth and stake their claim.                    </p>
                    
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Useful Links</h4>
                  <div className="footer-link">
                    <ul>
                    <li >
                        <Link
                          to="#whitepaper"
                          className={"section-link"}
                          onClick={() => handleClickScroll("whitepaper")}
                        >
                          WhitePaper
                        </Link>
                      </li>
                      <li >
                        <Link
                          to="#tokenomics"
                          className={"section-link"}
                          onClick={() => handleClickScroll("tokenomics")}
                        >
                          Tokenomics
                        </Link>
                      </li>
                      <li >
                        <Link
                          to="#team"
                          className={"section-link"}
                          onClick={() => handleClickScroll("team")}
                        >
                          Team
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#faq"
                          className={"section-link"}
                          onClick={() => handleClickScroll("faq")}
                        >
                          Faq
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title">Community</h4>
                  <div className="footer-link footer-content">
                  <ul className="footer-social">
                      <li>
                      <a href="https://t.me/MetalliumAlpha" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTelegramPlane} size="1x" />
                    </a>
                      </li>
                      <li>
                      <a href="https://x.com/MetalliumAlpha" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faXTwitter} size="1x" />
                    </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
 
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="copyright-text text-center">
                  <p>Copyright &copy; 2024. All Rights Reserved MTLA</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
