import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane, faTwitter, faRedditAlien, faXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faSquareXTwitter } from "@fortawesome/free-brands-svg-icons/faSquareXTwitter";


const Disclaimer = (props) => {
  return (
    <section id="disclaimer" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row">

          <div className="col-lg-12 community">
            <div className="about-content-community wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <h2 className="title">
                Join Our Community
                </h2>
              </div>
              <p>
              Ready to be part of something exciting? Follow us on social media, join our discussions, 
              and contribute to the development of MTLA. 
              The future is decentralized, and with your help, it’s going to be AI-powered!
              </p>
              <ul className="social">
                <li>
                    <a href="https://t.me/MetalliumAlpha" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTelegramPlane} size="3x" />
                    </a>
                </li>
                <li>
                    <a href="https://x.com/MetalliumAlpha" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faXTwitter} size="3x" />
                    </a>
                </li>
                </ul>


            </div>
          </div>

          {/* <div className="col-lg-12 about-disclaimer">
            <img src={about2}></img>
          </div> */}

        </div>
      </div>
    </section>
  );
};

export default Disclaimer;
