import React, { useEffect, useState } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import RoadmapOneItem from "./RoadmapOneItem";
import icon01 from "../../assets/img/icon/choose_icon01.svg";
import icon02 from "../../assets/img/icon/choose_icon02.svg";
import icon03 from "../../assets/img/icon/choose_icon03.svg";

const RoadmapOne = (props) => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCount(current), // Track the active slide
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      title: "Launch Phase",
      titletwo: "(PHASE 1)",
      dot1: "- Aim and vision",
      dot2: "- Social Media",
      dot3: "- Smart Contract Deploy ",
      dot4: "- Website Launch",
    },
    {
      title: "Cruise Phase ",
      titletwo: "(PHASE 2)",
      dot1: "- Transparent Details for buyers",
      dot2: "- Community Development",
      dot3: "- Awareness Campaign",
    },
    {
      title: "Encounter Phase",
      titletwo: "(PHASE 3)",
      dot1: "- Pre Sales",
      dot2: "- Game Development ",
      dot3: "- Partnership collaboration",
    },
    {
      title: "Extended Operations Phase",
      titletwo: "(PHASE 4)",
      dot1: "- Dex & Cex Listing ",
      dot2: "- Extensive Community Growth ",
      dot3: "- Enhancing Gaming Experience ",
    },
    {
      title: "Veni Vidi Vici",
      titletwo: "(PHASE 5)",
      dot1: "- Financial growth ",
      dot2: "- Land in Planet Terra 101 ",
      dot3: "- Conquer Earth & Terra 101",
    },
  ];

  const [count, setCount] = useState(0); // Initialize count to track active slide

  const toMultiply = 100 / 5; // Percentage increment per slide

  return (
    <section id="roadmap" className="about-area pt-80 pb-130">
      <div className="container">
        <div className="row choose-area roadmap-new align-items-center">
          <h2 className="roadmap-title wow fadeInRight" data-wow-delay=".2s">
            Roadmap
          </h2>

          <div className="row choose-active">
            <SlickSlider settings={slickSettings}>
              {slider_items.map((item, index) => (
                <div key={index} className="col-lg-3">
                  <RoadmapOneItem item={item} />
                </div>
              ))}
            </SlickSlider>
          </div>

          {/* Progress bar */}
          <div className="slide-progressbar">
            <div
              style={{
                width: `100%`,
                backgroundSize: `${(count + 1) * toMultiply}% 100%`,
              }}
              className="slide-filler"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadmapOne;
