// import React, { useState, useEffect } from "react";
// import Web3 from "web3";
// import axios from "axios";
// import shape01 from "../../assets/img/images/ethereum-eth.svg";
// import shape02 from "../../assets/img/images/usdt.svg";
// import CountDownOne from "../CountDown/CountDownOne";
// import presaleABI from "./presaleABI.json"; // Save your ABI as a JSON file and import it
// import { useAccount, useConnect, useDisconnect } from 'wagmi';
// import { ConnectButton } from "@rainbow-me/rainbowkit";

// const presaleAddress = "0x1DF8490491D2AfC6C56F1679dD7767135946797D"; // Presale contract address


// const Banner = (props) => {


//   const [activeCurrency, setActiveCurrency] = useState("ETH");
//   const [amount, setAmount] = useState("");
//   const [tokensReceived, setTokensReceived] = useState("");
//   const [bnbPrice, setBnbPrice] = useState(null);
//   const [web3, setWeb3] = useState(null);
//   const [contract, setContract] = useState(null);
//   const [connectedAddress, setConnectedAddress] = useState(null);
//   // const [buttonText, setButtonText] = useState("Connect");
//   const [popupMessage, setPopupMessage] = useState(""); // State for popup messages
//   const { isConnected, address } = useAccount();
//   const [totalRaised, setTotalRaised] = useState(0); // State for total raised amount

  

//   const handleButtonClick = (currency) => {
//     setActiveCurrency(currency);
//     setAmount(""); // Reset the amount input value to an empty string
//     setTokensReceived(""); // Reset the tokens received value
//   };

//   useEffect(() => {
//     if (window.ethereum) {
//       if (isConnected) {
//       const web3Instance = new Web3(window.ethereum);
//       setWeb3(web3Instance);
//       const contractInstance = new web3Instance.eth.Contract(presaleABI, presaleAddress);
//       setContract(contractInstance);
      
  
//       setConnectedAddress(address);

//       fetchTotalRaised(contractInstance);

//     }

//   }

//     // Fetch BNB price
//     fetchBnbPrice();

//   }, [isConnected]);

//   const fetchBnbPrice = async () => {
//     try {
//       const response = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd");
//       if (response.data && response.data.ethereum && response.data.ethereum.usd) {
//         setBnbPrice(response.data.ethereum.usd);
//       } else {
//         console.error("Unexpected response format:", response.data);
//       }
//     } catch (error) {
//       console.error("Error fetching ETH price:", error.message);
//       displayPopup("Error fetching ETH price: " + error.message);
//     }
//   };

//     const fetchTotalRaised = async (contractInstance) => {
//     const tokenPriceUSD = 0.00030;

//     try {
//       const tokensSold = await contractInstance.methods.TokenSold().call();

//       if (!tokensSold) {
//         console.log("No Token Sold yet.");
//         return;
//     }

//       const tokensSoldInUnits = web3.utils.fromWei(tokensSold, 'ether'); // Convert from wei to token units
      
//       const totalRaisedUSD = parseFloat(tokensSoldInUnits) * tokenPriceUSD; // Calculate total raised in USD
//       setTotalRaised(totalRaisedUSD.toFixed(2));
//     } catch (error) {
//       console.error("Error fetching total raised:", error.message);
//       displayPopup("Error fetching total raised: " + error.message);
//     }
//   };

//   const calculateTokensReceived = (amount) => {
//     const tokenPriceUSD = 0.013;
//     if (activeCurrency === "ETH" && bnbPrice) {
//       const amountInUSD = amount * bnbPrice;
//       return (amountInUSD / tokenPriceUSD).toFixed(2);
//     } else {
//       return (amount / tokenPriceUSD).toFixed(2);
//     }
//   };

//   const handleAmountChange = (e) => {
//     const value = e.target.value;
//     setAmount(value);
//     if (value && !isNaN(value)) {
//       setTokensReceived(calculateTokensReceived(value));
//     } else {
//       setTokensReceived("");
//     }
//   };

//   const buyTokens = async () => {
//     if (!isConnected || !contract || !amount) {
//         displayPopup("Please connect your wallet and enter a valid amount."); // Display error in popup
//         return;
//     }

//     try {
//         const isPresaleActive = await contract.methods.presaleStatus().call();
//         if (!isPresaleActive) {
//             displayPopup("Presale is not active right now. Please try later.");
//             return;
//         }

//         if (activeCurrency === "ETH") {
//             await contract.methods.BuyWithETH().send({
//                 from: address, // Use the address from the useAccount() hook
//                 value: web3.utils.toWei(amount, 'ether')
//             });
//         } else if (activeCurrency === "USDT") {
//             const usdtAmount = web3.utils.toWei(amount, 'mwei'); // USDT typically uses 6 decimal places
//             await contract.methods.BuyTokenWithUSDT(usdtAmount).send({
//                 from: address // Use the address from the useAccount() hook
//             });
//         }

//         displayPopup("Purchase successful!"); // Display success in popup
//     } catch (error) {
//         console.error("Error buying tokens:", error.message);
//         displayPopup("Error buying tokens: " + error.message); // Display error in popup
//     }
// };

//   const displayPopup = (message) => {
//     setPopupMessage(message);
//     setTimeout(() => {
//       setPopupMessage("");
//     }, 5000); // Clear message after 5 seconds
//   };

//   return (
//     <section className="banner-area banner-bg">


//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-lg-7">
//             <div className="banner-content pt-120 text-center">
//             <h5 className="wow fadeInRight"
//                     data-wow-delay=".2s" >
//                     • Presale Will Be Live Soon 
//                 </h5>
//               <h2 className="title wow fadeInRight"
//               data-wow-delay=".2s" >
//               A new age of Gold Rush<span><br/>
//               "Metallium Alpha," on Planet Terra101 </span>
//               </h2>
//             </div>

//           </div>

//           <div className="col-lg-5">
//             <div id="box" className="box text-center">
//               <div className="timer">              
//               <CountDownOne />
//               </div>
//               <div className="presale-info">

              
//               <div className="row seperate-bar">
//                   <div className="col-lg-12 presale-stage">
//                       <p className="stage">Current Stage <br/>  <span> Stage 1</span></p>
//                       <p className="raised">Total Raised <br/>  <span>${totalRaised}</span></p>
//                   </div>
//               </div>

//               <div className="row ">
//                   <div className="col-lg-12 presale-stage">
//                       <p className="price"> 1MTLA = $0.00030</p>
//                       <p className="next-price">Next Stage: $0.00032</p>
//                   </div>
//               </div>

         

//                 <div className="row justify-content-center">
//                   <div className="col-lg-12 button-row" style={{ display: "flex", justifyContent: "center" }}>
//                     <button className={`BNB  btn btn-two ${activeCurrency === "ETH" ? "active" : ""}`} onClick={() => handleButtonClick("ETH")}>
//                       <img className="bnb-image" src={shape01} alt="ETH" /> ETH
//                     </button>
//                     <button className={`USDT btn btn-two ${activeCurrency === "USDT" ? "active" : ""}`} onClick={() => handleButtonClick("USDT")}>
//                       <img className="usdt-image" src={shape02} alt="USDT" /> USDT
//                     </button>
//                   </div>
//                 </div>

//               </div>

//               <div className="row">
//                   <div className="col-md-12 placeholders">
//                     <p className="amount">
//                       Amount in {activeCurrency}
//                       <br />
//                       <input type="text" placeholder={activeCurrency} value={amount} onChange={handleAmountChange} />
//                     </p>
//                     <p className="amount">
//                       Tokens Receive
//                       <br />
//                       <input type="text" placeholder="" value={tokensReceived} readOnly />
//                     </p>
//                   </div>
//                 </div>


//               <div className="row">

//               <div className="col-lg-12 btn-col">
//                     {isConnected ? (
//                       <button className="btn" onClick={buyTokens}>Buy Tokens</button>
//                     ) : (
//                       <ConnectButton />
//                     )}
//                   </div>
                
//               </div>



//             </div>
//           </div>

//         </div>
        

//       </div>

//             {/* Popup box for displaying messages */}
//             {popupMessage && (
//         <div className="popup">
//           <div className="popup-content">
//             <p>{popupMessage}</p>
//           </div>
//         </div>
//       )}
      
//     </section>

    
//   );
// };

// export default Banner;





import React, { useState, useEffect } from "react";
import axios from "axios";
import shape01 from "../../assets/img/images/ethereum-eth.svg";
import shape02 from "../../assets/img/images/usdt.svg";
import CountDownOne from "../CountDown/CountDownOne";
import presaleABI from "./presaleABI.json"; // Ensure your ABI is correctly formatted
import erc20ABI from "./erc20ABI.json";

import {
  useConnect,
  useAccount,
  useReadContract,
  useWriteContract,
} from 'wagmi';
import { injected } from "wagmi/connectors";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { parseEther, parseUnits } from "viem";
import { mainnet } from "viem/chains";
import Web3 from "web3";

const presaleAddress = "0xE8b83d451807437Db5E5583f4e18b6874d7a363b"; // Presale contract address
const usdtTokenAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";

const Banner = () => {
  const [activeCurrency, setActiveCurrency] = useState("ETH");
  const [amount, setAmount] = useState("");
  const [tokensReceived, setTokensReceived] = useState("");
  const [bnbPrice, setBnbPrice] = useState(null);
  const [totalRaised, setTotalRaised] = useState(0);
  const [popupMessage, setPopupMessage] = useState(""); // State for popup messages
  const { isConnected, address } = useAccount();
  const { connectAsync} = useConnect();
  const { writeContractAsync } = useWriteContract();
  const [contract, setContract] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [isConnectedWallet, setIsConnected] = useState(null)

  // Read presale status and tokens sold from contract
  const { data: presaleStatus } = useReadContract({
    address: presaleAddress,
    abi: presaleABI,
    functionName: 'saleActive',
  });

  const { data: tokensSold } = useReadContract({
    address: presaleAddress,
    abi: presaleABI,
    functionName: 'totalSold',
  });

  const { data: allowance } = useReadContract({
        address: usdtTokenAddress,
        abi: erc20ABI,
        functionName: 'allowance',
        args: [address, presaleAddress],
    });


  // const { write: buyTokens } = writeContractAsync({
  //   address: presaleAddress,
  //   abi: presaleABI,
  //   functionName: activeCurrency === "ETH" ? 'BuyWithETH' : 'BuyTokenWithUSDT',
  //   args: activeCurrency === "ETH" ? [amount] : [amount],
  //   overrides: {
  //     value: activeCurrency === "ETH" ? amount : undefined, // Only for ETH
  //   },
  // });

  useEffect(() => {
    fetchBnbPrice();
  }, []);

  useEffect(() => {
    if (tokensSold) {
      const tokenPriceUSD = 0.00030; // Assuming each token is priced at $0.00030
      const tokensSoldInUnits = parseFloat(tokensSold) / 1e18; // Adjust for token decimal places if necessary
      const totalRaisedUSD = (tokensSoldInUnits * tokenPriceUSD).toFixed(2);
      setTotalRaised(totalRaisedUSD);
    }
  }, [tokensSold]);

  useEffect(() => {
    if (isConnected) {
      // Check if the window.ethereum object exists (works on both desktop and mobile)
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        
        const contractInstance = new web3Instance.eth.Contract(presaleABI, presaleAddress);
        setContract(contractInstance);
        
        // For mobile support, explicitly request account access (for mobile wallets like MetaMask Mobile)
        if (window.ethereum.request) {
          window.ethereum.request({ method: 'eth_requestAccounts' }).then(() => {
            setIsConnected(true);
          }).catch(err => {
            console.error("User denied wallet connection", err);
          });
        }
      } else {
        console.error("No Ethereum provider detected");
      }
    }
  }, [isConnected]); // Re-run effect when isConnected state changes

  const fetchBnbPrice = async () => {
    try {
      const response = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd");
      if (response.data && response.data.ethereum && response.data.ethereum.usd) {
        setBnbPrice(response.data.ethereum.usd);
      }
      else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching ETH price:", error.message);
      displayPopup("Error fetching ETH price: " + error.message);
    }
  };

  const calculateTokensReceived = (amount) => {
    const tokenPriceUSD = 0.00030; // Adjust token price as needed
    if (activeCurrency === "ETH" && bnbPrice) {
      const amountInUSD = amount * bnbPrice;
      return (amountInUSD / tokenPriceUSD).toFixed(2);
    } else {
      return (amount / tokenPriceUSD).toFixed(2);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    if (value && !isNaN(value)) {
      setTokensReceived(calculateTokensReceived(value));
    } else {
      setTokensReceived("");
    }
  };

  const handleButtonClick = (currency) => {
    setActiveCurrency(currency);
    setAmount(""); // Reset the amount input value to an empty string
    setTokensReceived(""); // Reset the tokens received value
  };

  const displayPopup = (message) => {
    setPopupMessage(message);
    setTimeout(() => {
      setPopupMessage("");
    }, 5000); // Clear message after 5 seconds
  };


const buyTokens = async () => {
    if (!isConnected) {
        displayPopup("Please connect your wallet.");
        return;
    }

    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
        displayPopup("Please enter a valid amount.");
        return;
    }

    if (!presaleStatus) {
        displayPopup("Presale is not active right now. Please try later.");
        return;
    }

    if (activeCurrency !== "ETH" && activeCurrency !== "USDT") {
        displayPopup("Unsupported currency selected.");
        return;
    }

    try {
        const ethAmount = parseEther(amount.toString()); // For ETH payments
        const usdtDecimals = 6;
        const usdtAmount = parseUnits(amount.toString(), usdtDecimals); // For USDT payments

        if (activeCurrency === "ETH") {
            await writeContractAsync({
                address: presaleAddress,
                abi: presaleABI,
                functionName: 'buyTokens',
                args: [],
                overrides: { 
                  value: ethAmount 
                },
            });
            displayPopup("Purchase successful with ETH!");
        } else if (activeCurrency === "USDT") {
            if (parseFloat(allowance) < parseFloat(usdtAmount)) {
                await writeContractAsync({
                    address: usdtTokenAddress,
                    abi: erc20ABI,
                    functionName: 'approve',
                    args: [presaleAddress, usdtAmount],
                });
                console.log("Approval successful!");
            }

            await writeContractAsync({
                address: presaleAddress,
                abi: presaleABI,
                functionName: 'buyWithUSDT',
                args: [usdtAmount],
            });
            displayPopup("Purchase successful with USDT!");
        }
    } catch (error) {
        console.error("Error buying tokens:", error);
        displayPopup("Error buying tokens: " + error.message);
    }
};

const buyTokensWithETH = async () => {

  if (!isConnected) {
    displayPopup("Please connect your wallet.");
    return;
}

if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
    displayPopup("Please enter a valid amount.");
    return;
}

try{

  await contract.methods.buyTokens().send({
    from: address,
    value: web3.utils.toWei(amount, 'ether')
  })
  displayPopup("Purchase successful with ETH!");

  } catch (error) {
    console.error("Error buying tokens:", error);
    displayPopup("Error buying tokens: " + error.message);
  }
};

//   const ethAmount = web3.utils.toWei(amount, 'ether')

//   console.log('ETH Amount in Wei:', ethAmount);

//   try{

//   if (!address){
    
//       await connectAsync({chainId: mainnet.id, connector: injected()})
//   }

//   const data = await writeContractAsync({
//                 chainId: mainnet.id,
//                 address: presaleAddress,
//                 abi: presaleABI,
//                 functionName: 'buyTokens',
//                 args: [ ],
//                 overrides: { 
//                   value: ethAmount, // Send 0.1 ETH for testing
//                 },
//             });

//     displayPopup("Purchase successful with ETH!");
      
//   } catch(err){
//     console.log(err)
//   }

// };
  
  return (
    <section className="banner-area banner-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="banner-content pt-120 text-center">
              <h5 className="wow fadeInRight" data-wow-delay=".2s">• Presale is Live</h5>
              <h2 className="title wow fadeInRight" data-wow-delay=".2s">
                A new age of Gold Rush<span><br /> "Metallium Alpha," on Planet Terra101 </span>
              </h2>
            </div>
          </div>
          <div className="col-lg-5">
            <div id="box" className="box text-center">
              <div className="timer">
                <CountDownOne />
              </div>
              <div className="presale-info">
                <div className="row seperate-bar">
                  <div className="col-lg-12 presale-stage">
                    <p className="stage">Current Stage <br /> <span> Stage 1</span></p>
                    <p className="raised">Total Raised <br /> <span>${totalRaised}</span></p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 presale-stage">
                    <p className="price"> 1MTLA = $0.00030</p>
                    <p className="next-price">Next Stage: $0.00032</p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-12 button-row" style={{ display: "flex", justifyContent: "center" }}>
                    <button className={`BNB btn btn-two ${activeCurrency === "ETH" ? "active" : ""}`} onClick={() => handleButtonClick("ETH")}>
                      <img className="bnb-image" src={shape01} alt="ETH" /> ETH
                    </button>
                    <button className={`USDT btn btn-two ${activeCurrency === "USDT" ? "active" : ""}`} disabled onClick={() => handleButtonClick("USDT")}>
                      <img className="usdt-image" src={shape02} alt="USDT" /> USDT
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 placeholders">
                    <p className="amount">
                      Amount in {activeCurrency}
                      <br />
                      <input type="text" placeholder={activeCurrency} value={amount} onChange={handleAmountChange} />
                    </p>
                    <p className="amount">
                      Tokens Receive
                      <br />
                      <input type="text" placeholder="" value={tokensReceived} readOnly />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 btn-col">
                    {isConnected ? (
                      <button className="btn" onClick={buyTokensWithETH}>Buy Tokens</button>
                    ) : (
                      <ConnectButton />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popup box for displaying messages */}
      {popupMessage && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupMessage}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Banner;
