import React from "react";
import aboutImg from "../../assets/img/images/image.png";

const About = () => {
  return (
    <section id="about" className="about-area-two">
      <div className="container custom-container-four">
        <div className="row align-items-center">

          <div className="col-lg-6">
            <div
              className="about-img-two text-center wow fadeInLeft glowing-background"
              data-wow-delay=".2s"
            >
              <img className="about-image" src={aboutImg} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-15">
                {/* <span className="sub-title">ABOUT</span> */}
                <h2 className="title">
                What is Metallium Alpha?
                </h2>
              </div>
              <p>
              Metallium Alpha is a community-focused cryptocurrency built to keep up with the growing crypto world. Our goal is to give everyone a chance to join and succeed in this new financial space.
             <br/><br/> Metallium Alpha is designed to make cryptocurrency accessible for everyone, bridging the gap between complex blockchain technology and everyday users. It offers a decentralized platform that ensures security, transparency, and control over assets.
             <br/><br/> At its core, Metallium Alpha is community-driven, encouraging active user participation. By fostering collaboration and innovation, it creates a dynamic ecosystem where members can contribute and share in the rewards, staying ahead in the evolving crypto world.
              </p>

{/* 
              <a
                href={aboutImg}
                download
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-two"
              >
                Download Document
              </a> */}
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default About;
