import React from "react";
import img01 from "../../assets/img/team/MR. FALPO.jpg";
import img02 from "../../assets/img/team/MR. V2.jpg";
import img03 from "../../assets/img/team/MR. ERIC.jpg";
import img04 from "../../assets/img/team/MR.MZAL.jpg";
import img05 from "../../assets/img/team/MR. BROOK.jpg";
import img06 from "../../assets/img/team/MR. FRIDAY.jpg";
import img07 from "../../assets/img/team/MR. YU WISH.jpg";

import TeamOneItem from "./TeamOneItem";

const TeamOne = () => {
  const team_members = [
    {
      src: img01,
      name: "Mr. Falpo",
      designation: "FLEET ADMIRAL",
    },
    {
      src: img02,
      name: "Mr. V2 ",
      designation: "ADMIRAL",
    },
    {
      src: img03,
      name: "Mr. Eric Ethereal",
      designation: "VICE ADMIRAL",
    },
    {
      src: img04,
      name: "Mr. M’zal",
      designation: "REAR ADMIRAL",
    },
    {
      src: img05,
      name: "MR. Brook",
      designation: "CAPTAIN",
    },
    {
      src: img06,
      name: "Mr. Friday",
      designation: "COMMODORE",
    },
    {
      src: img07,
      name: "Mr. Yu Wish",
      designation: "COMMANDER",
    },
  ];

  return (
    <section  className="team-area pt-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-70">
              <h2 className="title">
                The Leadership <span>Team</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((member, index) => (
            <div key={index} className="col-xl-3 col-md-4 col-sm-6">
              <TeamOneItem item={member} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
