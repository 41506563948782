import React from "react";
import { Link } from "react-router-dom";
import docImg from "../../assets/img/images/paper.png";

const WhitePaper = () => {

  const downloadPDF = () => {
    window.open("https://drive.usercontent.google.com/u/0/uc?id=1zjCGPo9kgU7Cq5hZURm7zwkFdXBvyzHj&export=download", '_blank');
  };


  return (
    <section id="whitepaper" className="document-area pt-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 order-2 order-lg-0">
            <div
              className="document-img text-center wow fadeInUp"
              data-wow-delay=".2s"
            >
              <img src={docImg} alt="" />
            </div>
          </div>

          <div className="col-lg-5 col-md-7">
            <div
              className="document-content mt-50 wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title mb-35">
                <span className="sub-title">Whitepaper</span>
                <h2 className="title">
                  Read MTLA <span>Whitepaper</span>
                </h2>
              </div>

              <ul className="document-list">
                <li>Introduction</li>
                <li>Crypto Revolution</li>
                <li>DeFi Gaming</li>
                <li>Blockchain</li>
                <li>Vision and Roadmap</li>
                <li>Linear Vesting</li>

              </ul>

           <a
                href={"https://drive.google.com/file/d/1zjCGPo9kgU7Cq5hZURm7zwkFdXBvyzHj/view?usp=sharing"} 
                // download
                download target="_blank" rel="noopener noreferrer"
                className="btn whitepaper-btn"
                onClick={downloadPDF} 
              >
                Read Whitepaper
              </a>
              

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhitePaper;
