import React from "react";

const ContactTwoForm = () => {

  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "0cbce7e4-f12f-4b28-abfa-3db7d33c9bac");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };


  return (
    <div className="contact-form-wrap-two wow fadeInRight" data-wow-delay=".2s">
      <h2 className="title">Lets Get in touch with us</h2>
      <form onSubmit={onSubmit}>
        <input type="text" placeholder="Name" required />
        <input type="email" placeholder="Email Address" required />
        <textarea name="massage" placeholder="Massage" required></textarea>
        <button type="submit" className="btn btn-two">
          Send Massage
        </button>
      </form>
      <span>{result}</span>
    </div>
  );
};

export default ContactTwoForm;
