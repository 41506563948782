import React from "react";
import StakeToken from "../../components/StakeToken/StakeToken";

const Staking = () => {
  return (
    <StakeToken />
  );
};

export default Staking;
