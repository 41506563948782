import React from "react";

import FaqItem from "./FaqItem";

const Faq = () => {
  const faq_items = [
    {
      id: "headingOne",
      controls: "collapseOne",
      expanded: true,
      btnClass: "",
      contentClass: "show",
      title: "What is Metallium Alpha?",
      details: `Metallium Alpha is an Ethereum-based token designed for innovation and real-world utility, 
      offering users the opportunity to be involved in the growing crypto world.`,
    },
    {
      id: "headingTwo",
      controls: "collapseTwo",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "How to buy Metallium Alpha?",
      details: `To buy Metallium Alpha during the presale, you can purchase using either ETH or USDT. Simply connect your wallet, choose the payment option (ETH or USDT), and complete the transaction 
      on the presale platform. Ensure you have enough funds and follow the on-screen instructions.`,
    },
    {
      id: "headingThree",
      controls: "collapseThree",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "When will Metallium Alpha be listed?",
      details: `Metallium Alpha will be listed within 48 hours after the completion of the presale period.`,
    },
    {
      id: "headingFour",
      controls: "collapseFour",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "How to claim Metallium Alpha token after buying?",
      details: `After purchasing Metallium Alpha during the presale, 
      you can claim your tokens within 24 hours. Simply return to the presale platform, 
      connect your wallet, and click the "Claim Tokens" button. 
      Follow the prompts to finalize the transaction and receive your tokens in your wallet.`,
    },
  ];

  return (
    <section id="faqs" className="faq-area">
      <div className="container custom-container-four">

        <div className="row">
          <div className="col-lg-12">
            <div className="section-title section-title-two text-center mb-60">
              <h2 className="title" style={{color: 'white'}}>Frequently asked questions</h2>
            </div>

            <div className="faq-wrap wow fadeInUp" data-wow-delay=".2s">
              <div className="accordion" id="accordionExample">
                {faq_items.map((x, index) => (
                  <FaqItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
